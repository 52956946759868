import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

// This year is used to know which year the observations have existed in the database.
export const LAST_YEAR = 2006
export const NAIVE_ISO_FORMAT = 'YYYY-MM-DDTHH:mm'

export function getCurrentYear() {
  return parseInt(dayjs().format('YYYY'))
}

export function dateISOtoYear(isoDate: string) {
  return parseInt(dayjs(isoDate).format('YYYY'))
}

export function dateISOtoMonth(isoDate: string) {
  return parseInt(dayjs(isoDate).format('MM'))
}

export function humanizedDate(date: string, utcOffset: number) {
  return dayjs(date).utcOffset(utcOffset).format('YYYY-MM-DD HH:mm')
}

export function humanizeTimezone(date: string, utcOffset: number) {
  return dayjs(date).utcOffset(utcOffset).format('Z')
}

export function getCurrentDateTzNaive() {
  return dayjs().format(NAIVE_ISO_FORMAT)
}

export function getMinDateTzNaive() {
  return dayjs().subtract(1, 'year').format(NAIVE_ISO_FORMAT)
}

/*
Allows to append the local timezone to a naive datetime, dayjs() default behavior, and formatting it to ISO string expected by the server 
*/
export function formatDateTzAwareISO(unformattedDate: string) {
  return dayjs(unformattedDate).toISOString()
}

export function getNowUTCOffset() {
  return dayjs().utcOffset()
}

export function getUTCOffset(date: string) {
  return dayjs(date).utcOffset()
}

export function pageIsEmbed() {
  return window.location.search.includes('?embed')
}
