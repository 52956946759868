import i18n from '@/plugins/i18n'
export * from './selectImages'
export * from './maps'
export * from './help'

// This const is not used for now in any file, but is used to add the constellation keys in each language file using vue-i18n-extract
export const constellations = [
  // eslint-disable-next-line
  // @ts-ignore
  i18n.global.t('Orion'),
  i18n.global.t('Leo'),
  i18n.global.t('Crux'),
  i18n.global.t('Bootes'),
  i18n.global.t('Hercules'),
  i18n.global.t('Scorpius'),
  i18n.global.t('Sagittarius'),
  i18n.global.t('Cygnus'),
  i18n.global.t('Pegasus'),
  i18n.global.t('Perseus'),
  i18n.global.t('Grus'),
  i18n.global.t('Canis-Major'),
  i18n.global.t('Gemini'),
  i18n.global.t('Taurus'),
]
