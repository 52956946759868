/* tslint:disable */
/* eslint-disable */
/**
 * Globe At Night API
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Observation } from '../models';
// @ts-ignore
import { ObservationBrief } from '../models';
// @ts-ignore
import { ObservationRequest } from '../models';
// @ts-ignore
import { PaginatedObservationList } from '../models';
/**
 * ObservationsApi - axios parameter creator
 * @export
 */
export const ObservationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Observation
         * @param {ObservationRequest} observationRequest 
         * @param {string} [scistarterEmail] Email of the participant to add the participation to its SciStarter account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsCreate: async (observationRequest: ObservationRequest, scistarterEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'observationRequest' is not null or undefined
            assertParamExists('observationsCreate', 'observationRequest', observationRequest)
            const localVarPath = `/api/observations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scistarterEmail !== undefined) {
                localVarQueryParameter['scistarter_email'] = scistarterEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Observations list
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {number} [radius] 
         * @param {number} [year] Filter by year or by default all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsList: async (latitude?: number, longitude?: number, page?: number, pageSize?: number, radius?: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/observations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this observation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('observationsRetrieve', 'id', id)
            const localVarPath = `/api/observations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Observations list by mandatory year, results are not paginated
         * @param {string} year 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsYearList: async (year: string, latitude?: number, longitude?: number, radius?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('observationsYearList', 'year', year)
            const localVarPath = `/api/observations/year/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObservationsApi - functional programming interface
 * @export
 */
export const ObservationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObservationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Observation
         * @param {ObservationRequest} observationRequest 
         * @param {string} [scistarterEmail] Email of the participant to add the participation to its SciStarter account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observationsCreate(observationRequest: ObservationRequest, scistarterEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Observation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observationsCreate(observationRequest, scistarterEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Observations list
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {number} [radius] 
         * @param {number} [year] Filter by year or by default all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observationsList(latitude?: number, longitude?: number, page?: number, pageSize?: number, radius?: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedObservationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observationsList(latitude, longitude, page, pageSize, radius, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this observation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observationsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Observation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observationsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Observations list by mandatory year, results are not paginated
         * @param {string} year 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observationsYearList(year: string, latitude?: number, longitude?: number, radius?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObservationBrief>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observationsYearList(year, latitude, longitude, radius, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObservationsApi - factory interface
 * @export
 */
export const ObservationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObservationsApiFp(configuration)
    return {
        /**
         * Create Observation
         * @param {ObservationRequest} observationRequest 
         * @param {string} [scistarterEmail] Email of the participant to add the participation to its SciStarter account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsCreate(observationRequest: ObservationRequest, scistarterEmail?: string, options?: any): AxiosPromise<Observation> {
            return localVarFp.observationsCreate(observationRequest, scistarterEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Observations list
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {number} [radius] 
         * @param {number} [year] Filter by year or by default all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsList(latitude?: number, longitude?: number, page?: number, pageSize?: number, radius?: number, year?: number, options?: any): AxiosPromise<PaginatedObservationList> {
            return localVarFp.observationsList(latitude, longitude, page, pageSize, radius, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this observation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsRetrieve(id: number, options?: any): AxiosPromise<Observation> {
            return localVarFp.observationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Observations list by mandatory year, results are not paginated
         * @param {string} year 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observationsYearList(year: string, latitude?: number, longitude?: number, radius?: number, options?: any): AxiosPromise<Array<ObservationBrief>> {
            return localVarFp.observationsYearList(year, latitude, longitude, radius, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for observationsCreate operation in ObservationsApi.
 * @export
 * @interface ObservationsApiObservationsCreateRequest
 */
export interface ObservationsApiObservationsCreateRequest {
    /**
     * 
     * @type {ObservationRequest}
     * @memberof ObservationsApiObservationsCreate
     */
    readonly observationRequest: ObservationRequest

    /**
     * Email of the participant to add the participation to its SciStarter account
     * @type {string}
     * @memberof ObservationsApiObservationsCreate
     */
    readonly scistarterEmail?: string
}

/**
 * Request parameters for observationsList operation in ObservationsApi.
 * @export
 * @interface ObservationsApiObservationsListRequest
 */
export interface ObservationsApiObservationsListRequest {
    /**
     * 
     * @type {number}
     * @memberof ObservationsApiObservationsList
     */
    readonly latitude?: number

    /**
     * 
     * @type {number}
     * @memberof ObservationsApiObservationsList
     */
    readonly longitude?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ObservationsApiObservationsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ObservationsApiObservationsList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {number}
     * @memberof ObservationsApiObservationsList
     */
    readonly radius?: number

    /**
     * Filter by year or by default all
     * @type {number}
     * @memberof ObservationsApiObservationsList
     */
    readonly year?: number
}

/**
 * Request parameters for observationsRetrieve operation in ObservationsApi.
 * @export
 * @interface ObservationsApiObservationsRetrieveRequest
 */
export interface ObservationsApiObservationsRetrieveRequest {
    /**
     * A unique integer value identifying this observation.
     * @type {number}
     * @memberof ObservationsApiObservationsRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for observationsYearList operation in ObservationsApi.
 * @export
 * @interface ObservationsApiObservationsYearListRequest
 */
export interface ObservationsApiObservationsYearListRequest {
    /**
     * 
     * @type {string}
     * @memberof ObservationsApiObservationsYearList
     */
    readonly year: string

    /**
     * 
     * @type {number}
     * @memberof ObservationsApiObservationsYearList
     */
    readonly latitude?: number

    /**
     * 
     * @type {number}
     * @memberof ObservationsApiObservationsYearList
     */
    readonly longitude?: number

    /**
     * 
     * @type {number}
     * @memberof ObservationsApiObservationsYearList
     */
    readonly radius?: number
}

/**
 * ObservationsApi - object-oriented interface
 * @export
 * @class ObservationsApi
 * @extends {BaseAPI}
 */
export class ObservationsApi extends BaseAPI {
    /**
     * Create Observation
     * @param {ObservationsApiObservationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationsApi
     */
    public observationsCreate(requestParameters: ObservationsApiObservationsCreateRequest, options?: AxiosRequestConfig) {
        return ObservationsApiFp(this.configuration).observationsCreate(requestParameters.observationRequest, requestParameters.scistarterEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Observations list
     * @param {ObservationsApiObservationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationsApi
     */
    public observationsList(requestParameters: ObservationsApiObservationsListRequest = {}, options?: AxiosRequestConfig) {
        return ObservationsApiFp(this.configuration).observationsList(requestParameters.latitude, requestParameters.longitude, requestParameters.page, requestParameters.pageSize, requestParameters.radius, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ObservationsApiObservationsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationsApi
     */
    public observationsRetrieve(requestParameters: ObservationsApiObservationsRetrieveRequest, options?: AxiosRequestConfig) {
        return ObservationsApiFp(this.configuration).observationsRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Observations list by mandatory year, results are not paginated
     * @param {ObservationsApiObservationsYearListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservationsApi
     */
    public observationsYearList(requestParameters: ObservationsApiObservationsYearListRequest, options?: AxiosRequestConfig) {
        return ObservationsApiFp(this.configuration).observationsYearList(requestParameters.year, requestParameters.latitude, requestParameters.longitude, requestParameters.radius, options).then((request) => request(this.axios, this.basePath));
    }
}
