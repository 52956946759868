import Clear from '@/assets/img/sky/day/clear.png'
import Quarter from '@/assets/img/sky/day/quarter.png'
import Half from '@/assets/img/sky/day/half.png'
import Overcast from '@/assets/img/sky/day/overcast.png'
import ClearNight from '@/assets/img/sky/night/clear.png'
import QuarterNight from '@/assets/img/sky/night/quarter.png'
import HalfNight from '@/assets/img/sky/night/half.png'
import OvercastNight from '@/assets/img/sky/night/overcast.png'
import { useI18n } from 'vue-i18n'
import { reactive, watch } from 'vue'

export default function useSelectImages() {
  const { t, locale } = useI18n()
  const skyConditions = reactive(getSkyConditions())

  watch(locale, () => {
    // We use the functions and watch the locale to charge the translation of the current locale, otherwise, only charge the translation the first time
    Object.assign(skyConditions, getSkyConditions())
  })

  function getSkyConditions() {
    return [
      {
        id: 'cc_clear',
        value: 0,
        dayPath: Clear,
        nightPath: ClearNight,
        title: t('Clear'),
        imgWidth: '160',
      },
      {
        id: 'cc_25',
        value: 25,
        dayPath: Quarter,
        nightPath: QuarterNight,
        title: t('¼ of the sky'),
        imgWidth: '160',
      },
      {
        id: 'cc_50',
        value: 50,
        dayPath: Half,
        nightPath: HalfNight,
        title: t('½ of the sky'),
        imgWidth: '160',
      },
      {
        id: 'cc_overcast',
        value: 75,
        dayPath: Overcast,
        nightPath: OvercastNight,
        title: t('More than ½ of the sky'),
        imgWidth: '160',
      },
    ]
  }

  return { skyConditions }
}
