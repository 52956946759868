import { useDark, useToggle } from '@vueuse/core'

const isDark = useDark()
const toggleDark = useToggle(isDark)

// This composable let us share the global isDark and toggleDark in the entire page
export default function useDarkMode() {
  return {
    isDark,
    toggleDark,
  }
}
