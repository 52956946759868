import { useI18n } from 'vue-i18n'
import { reactive, watch } from 'vue'

export default function useHelp() {
  const { t, locale } = useI18n()
  const TIPS = reactive(getStepsTips())

  watch(locale, () => {
    // We use the functions and watch the locale to charge the translation of the current locale, otherwise, only charge the translation the first time
    Object.assign(TIPS, getStepsTips())
  })

  function getStepsTips() {
    return {
      STEP_ONE: [
        {
          text: t(
            'If needed, change the observation date and time using the current date and time format, below, as an example. Please make sure the Sun has set at least one hour before you take your measurements.'
          ),
        },
      ],
      STEP_TWO: [
        {
          text: t(
            'Use the map below to help you find the latitude and longitude of your location when you made your observations.'
          ),
        },
        {
          text: t(
            'Type the street address, the common name, or the latitude and longitude (in decimal degrees) of your location into the text box & click Map It!. Refine your location by zooming in, scrolling and tapping the map.'
          ),
        },
      ],
      STEP_THREE: [
        {
          text: t(
            'To select the magnitude chart that most closely resembles what you see, click the thumbnail images below the larger magnitude chart. This will load sky views at various magnitudes. The currently displayed magnitude chart is highlighted on the corresponding thumbnail. When you have found the chart that most closely resembles your view of the night sky, leave that thumbnail highlighted.'
          ),
        },
        {
          text: t(
            'Note: For the constellation Leo, the star charts for magnitudes 0-3 have a field of view of 100°. For magnitudes of 4-7 the field of view is 50°.'
          ),
        },
      ],
      STEP_FOUR: [
        {
          text: t('Were there any clouds? Estimate how much of the sky was covered (touch/click the image below)'),
        },
      ],
      STEP_FIVE: [
        {
          text: t('If so, please tell us your reading.'),
        },
      ],
      STEP_SIX: [
        {
          text: t(
            'If you have filled out all the information in the previous five steps, you are ready to send us your data. Take a moment to double-check that the data you have entered above is correct and then click the Submit Data button below. And thanks for being a Globe at Night citizen scientist!'
          ),
        },
      ],
    }
  }

  return { TIPS }
}
