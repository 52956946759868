<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
interface Props {
  loading: boolean
}

defineProps<Props>()
</script>

<template>
  <div v-if="loading" v-bind="$attrs" class="spinner-grow spinner-grow-sm" role="status"></div>
  <slot v-else>
    <i-bi-circle v-bind="$attrs"></i-bi-circle>
  </slot>
</template>
