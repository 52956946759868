import { useI18n } from 'vue-i18n'

export default function useLanguages() {
  const { locale } = useI18n()
  const RTL_LANGUAGES_LIST = ['fa']

  function isLanguageRTL() {
    return RTL_LANGUAGES_LIST.includes(locale.value)
  }

  return {
    isLanguageRTL,
  }
}
