import { Ref, ref } from 'vue'
import { campaignsApi } from '@/api'
import useToasts from './useToasts'
import { useI18n } from 'vue-i18n'
import { Campaign, Constellation, HemispheresEnum } from '@/api-client'
import { dateISOtoYear, dateISOtoMonth } from './useUtils'

export default function useConstellations(latitude: Ref<number>) {
  const { addToast } = useToasts()
  const { t } = useI18n()
  const campaign = ref<Campaign>()
  const campaignConstellations = ref<Constellation[]>([])
  const currentConstellation = ref<string>('')
  const loadingCampaigns = ref(false)

  async function loadConstellation(observationD: string, requestedConstellationKey?: string) {
    loadingCampaigns.value = true

    try {
      const { data } = await campaignsApi.mainCampaignsCurrentRetrieve({ date: observationD })
      campaign.value = data
      campaignConstellations.value = data.constellations.map((campaign) => {
        return campaign.constellation
      })

      setCurrentConstellationByLatitude(requestedConstellationKey)
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        campaignConstellations.value = []
        currentConstellation.value = ''
        addToast({ text: t('No campaigns found for this date'), variant: 'warning' })
      } else {
        addToast({ text: t('Error loading the campaigns'), variant: 'danger' })
      }
    }
    loadingCampaigns.value = false
  }

  function setCurrentConstellationByLatitude(requestedConstellationKey?: string) {
    const currentReportHemisphere: HemispheresEnum = latitude.value >= 0 ? HemispheresEnum.N : HemispheresEnum.S // We send the second parameter to receive only 'N' or 'S' hemisphere

    const requestedConstellation = campaignConstellations.value.find((constellation) => {
      return requestedConstellationKey && constellation.key === requestedConstellationKey
    })
    if (!currentConstellation.value && requestedConstellation && requestedConstellation.key) {
      // Only if it's the first time we set the requestedConstellation by URL
      currentConstellation.value = requestedConstellation.key
      return
    }

    if (campaign.value) {
      const currentHemisphereConstellations = campaign.value.constellations.filter((constellation) => {
        return constellation.hemispheres.some((hemisphere: HemispheresEnum) => {
          return hemisphere === currentReportHemisphere
        })
      })

      if (currentHemisphereConstellations.length > 0) {
        // Assign the first constellation key of the campaign with the current hemisphere
        currentConstellation.value = currentHemisphereConstellations[0].constellation.key
      }
    }

    // If current constellations is still empty then set the first in the list
    if (!currentConstellation.value && campaignConstellations.value.length > 0) {
      currentConstellation.value = campaignConstellations.value[0].key
    }
  }

  function getConstellationImgPath(
    constellation: string,
    isDark: boolean,
    size: string,
    latitude: number,
    magnitude: number,
    date?: string
  ) {
    let year = new Date().getFullYear()
    if (date && dateISOtoYear(date)) {
      year = dateISOtoYear(date)
    }
    if (year < 2023) {
      year = 2023 // The magnitude charts are only available from 2023
    }
    // If 2024, constellation is "orion", "pegasus" or "perseus", and month is december, add "-late" to the constellation name
    if (
      year === 2024 &&
      date &&
      dateISOtoMonth(date) === 12 &&
      ['orion', 'pegasus', 'perseus'].includes(constellation)
    ) {
      constellation = constellation + '-late'
    }
    return `${
      import.meta.env.VITE_MAGNITUDE_CHARTS_BASE_URL
    }${year}/${constellation.toLocaleLowerCase()}/${getPartialPathByCurrentBrowserTheme(
      isDark
    )}/${size}/${getHemisphereLatitude(latitude)}_${magnitude}5.png`
  }

  function getPartialPathByCurrentBrowserTheme(isDark: boolean) {
    return isDark ? 'night' : 'day'
  }

  function getHemisphereLatitude(latitude: number) {
    if (latitude <= -45) {
      return '50s'
    }
    if (latitude > -45 && latitude <= -35) {
      return '40s'
    }
    if (latitude > -35 && latitude <= -25) {
      return '30s'
    }
    if (latitude > -25 && latitude <= -15) {
      return '20s'
    }
    if (latitude > -15 && latitude <= -5) {
      return '10s'
    }
    if (latitude > -5 && latitude <= 0) {
      return '0'
    }
    if (latitude > 0 && latitude <= 5) {
      return '0'
    }
    if (latitude > 5 && latitude <= 15) {
      return '10'
    }
    if (latitude > 15 && latitude <= 25) {
      return '20'
    }
    if (latitude > 25 && latitude <= 35) {
      return '30'
    }
    if (latitude > 35 && latitude <= 45) {
      return '40'
    }
    if (latitude > 45 && latitude <= 55) {
      return '50'
    }
    if (latitude > 55) {
      return '60'
    }
    return ''
  }

  function getCurrentConstellationName(constellationKey: string) {
    const constellationNameFound = campaignConstellations.value.find((cons) => cons.key === constellationKey)?.name
    return constellationNameFound ? t(constellationNameFound) : ''
  }

  return {
    currentConstellation,
    campaignConstellations,
    loadingCampaigns,
    loadConstellation,
    getConstellationImgPath,
    getCurrentConstellationName,
    setCurrentConstellationByLatitude,
  }
}
