/* tslint:disable */
/* eslint-disable */
/**
 * Globe At Night API
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Campaign } from '../models';
/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * text
         * @param {string} [date] retrieve a campaign from a specific date or by default the current campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainCampaignsCurrentRetrieve: async (date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/main/campaigns/current/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * text
         * @param {string} [date] retrieve a campaign from a specific date or by default the current campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainCampaignsCurrentRetrieve(date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainCampaignsCurrentRetrieve(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * text
         * @param {string} [date] retrieve a campaign from a specific date or by default the current campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainCampaignsCurrentRetrieve(date?: string, options?: any): AxiosPromise<Campaign> {
            return localVarFp.mainCampaignsCurrentRetrieve(date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mainCampaignsCurrentRetrieve operation in CampaignsApi.
 * @export
 * @interface CampaignsApiMainCampaignsCurrentRetrieveRequest
 */
export interface CampaignsApiMainCampaignsCurrentRetrieveRequest {
    /**
     * retrieve a campaign from a specific date or by default the current campaign
     * @type {string}
     * @memberof CampaignsApiMainCampaignsCurrentRetrieve
     */
    readonly date?: string
}

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * text
     * @param {CampaignsApiMainCampaignsCurrentRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public mainCampaignsCurrentRetrieve(requestParameters: CampaignsApiMainCampaignsCurrentRetrieveRequest = {}, options?: AxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).mainCampaignsCurrentRetrieve(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}
