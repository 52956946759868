import { MainApi, ObservationsApi, CampaignsApi, Configuration } from '@/api-client'
import axiosInstance from './axios'

/**
 * Example API by Swagger
 * https://editor.swagger.io/
 */

const configGlobe = new Configuration({ basePath: import.meta.env.VITE_API_BASE_URL })

export const mainApi = new MainApi(configGlobe, undefined, axiosInstance)

export const observationsApi = new ObservationsApi(configGlobe, undefined, axiosInstance)

export const campaignsApi = new CampaignsApi(configGlobe, undefined, axiosInstance)
