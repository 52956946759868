/* tslint:disable */
/* eslint-disable */
/**
 * Globe At Night API
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Country } from '../models';
/**
 * MainApi - axios parameter creator
 * @export
 */
export const MainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainCountriesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/main/countries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainCountriesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mainCountriesRetrieve', 'id', id)
            const localVarPath = `/api/main/countries/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainApi - functional programming interface
 * @export
 */
export const MainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainCountriesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainCountriesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainCountriesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Country>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainCountriesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainApi - factory interface
 * @export
 */
export const MainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainCountriesList(options?: any): AxiosPromise<Array<Country>> {
            return localVarFp.mainCountriesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainCountriesRetrieve(id: number, options?: any): AxiosPromise<Country> {
            return localVarFp.mainCountriesRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mainCountriesRetrieve operation in MainApi.
 * @export
 * @interface MainApiMainCountriesRetrieveRequest
 */
export interface MainApiMainCountriesRetrieveRequest {
    /**
     * A unique integer value identifying this country.
     * @type {number}
     * @memberof MainApiMainCountriesRetrieve
     */
    readonly id: number
}

/**
 * MainApi - object-oriented interface
 * @export
 * @class MainApi
 * @extends {BaseAPI}
 */
export class MainApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public mainCountriesList(options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).mainCountriesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MainApiMainCountriesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public mainCountriesRetrieve(requestParameters: MainApiMainCountriesRetrieveRequest, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).mainCountriesRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
