import { ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { Loader } from '@googlemaps/js-api-loader'
import { COMMON_GOOGLE_CONFIGURATION, MapLocation } from '@/config'
import useToasts from './useToasts'
import { GoogleMap } from '@capacitor/google-maps'

export function initialLocation() {
  return {
    lat: 0,
    lng: 0,
  }
}

export default function useGoogleMaps(globeMap: GoogleMap) {
  const { addToast } = useToasts()
  const { t } = useI18n()
  const coordinates = ref<MapLocation>(initialLocation())
  const searchAddress = ref<string>('')
  const markerId = ref<string>('')

  watchEffect(async () => {
    if (coordinates.value.lat === 0 && coordinates.value.lng === 0) {
      await globeMap.setCamera({
        coordinate: {
          lat: coordinates.value.lat,
          lng: coordinates.value.lng,
        },
        zoom: 1,
      })
    }
  })
  async function getGoogleLoaderInstance() {
    /*
    Loader init configuration should be the same that GoogleMap creation if use same apiKey,
    so, the id, libraries and version should be the same, therewise it fails
  */
    try {
      return await new Loader({
        ...COMMON_GOOGLE_CONFIGURATION,
        libraries: ['places'],
        version: 'weekly',
      }).load()
    } catch {
      addToast({ text: t('Error initializing Google Loader'), variant: 'danger' })
    }
  }

  async function searchCoordinatesByAddress() {
    const googleLoader = await getGoogleLoaderInstance()
    if (!googleLoader || !searchAddress.value) return

    try {
      const geocoder = new googleLoader.maps.Geocoder()

      await geocoder.geocode(
        { address: searchAddress.value },
        // eslint-disable-next-line no-undef
        (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
          if (googleLoader && status === googleLoader.maps.GeocoderStatus.OK && results) {
            coordinates.value = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }
          }
        }
      )
    } catch {
      addToast({ text: t('Error getting coordinates'), variant: 'danger' })
    }
  }

  async function addNewMarker(moveCameraAutomatically = true, zoomLevel = 12) {
    removeMarker()

    try {
      // Add a marker to the map
      markerId.value = await globeMap.addMarker({
        coordinate: {
          lat: coordinates.value.lat,
          lng: coordinates.value.lng,
        },
      })

      if (moveCameraAutomatically) {
        await globeMap.setCamera({
          coordinate: {
            lat: coordinates.value.lat,
            lng: coordinates.value.lng,
          },
          zoom: zoomLevel,
        })
      }
    } catch {
      addToast({ text: t('Error setting marker'), variant: 'danger' })
    }
  }

  function removeMarker() {
    if (markerId.value) {
      // Remove previous marker before creating new
      globeMap.removeMarker(markerId.value)
    }
  }

  return {
    coordinates,
    searchAddress,
    getGoogleLoaderInstance,
    searchCoordinatesByAddress,
    addNewMarker,
    removeMarker,
  }
}
