/* tslint:disable */
/* eslint-disable */
/**
 * Globe At Night API
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum HemispheresEnum {
    N = 'N',
    S = 'S'
}


